/*= ===============================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-monospace: 'Montserrat', Helvetica, Arial, serif !important;

.react-dataTable > div {
  overflow-x: auto;
}

.active-header-card {
  background: linear-gradient(118deg, #0377b166, rgba(0, 124, 186, 0.2)) !important;
  border-radius: 0.428rem;
}

.hover-card:hover {
  cursor: pointer;
}

.upload-photo {
  display: flex;
  flex-direction: column;
  justify-content: initial;
}

.module-content {
	&__section-dark {
		background-color: #161D31 !important;
	}
	&__item-dark {
		background-color: #343D55 !important;
	}
	&__section-light {
		background-color: #FFFFFF !important;
	}
	&__item-light {
		background-color: #F7F7F7 !important;
	}
}

.module-preview {
  &__bg {
    background-color: #FFFFFF;
    height: calc(100% - 75px);
    width: 78%;
    border-radius: 28px;
    top: 15px;
    z-index: -1;
  }

  &__bg--frame {
    background-size: 100% 100%;
    height: 650px;
    width: 300px;
    background-image: url('../images/preview/mobile-frame.png');
  }

  &__header {
    background-color: #FFFFFF;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    padding-top: 45px;
    padding-left: 8px;
    padding-bottom: 8px;
    max-width: calc(100% - 22px);
    margin-left: 10px;
    border-bottom: 1px solid #DAE1E7;

    &-icon {
      margin-left: 4px;
      padding-top: 4px;
    }

    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-border {
      width: 100%;
      border-radius: 3px;
      bottom: -8px !important;
    }
  }

  &__content {
    padding: 8px 12px 8px 12px !important;
    max-width: 266px !important;
    margin-left: 18px;
    max-height: calc(100% - 150px);
    background-color: #FFFFFF;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 39px;
    position: relative !important;
    z-index: 20 !important;
  // Scrollbar 
    scrollbar-width: thin;
    scrollbar-color: #D0D2D6;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #D0D2D6;
      border-radius: 30px;
    }
  }
}

.module-list {
  &__icon {
    width: 40px;
    height: 40px;
  }
}

body {
  padding-right: 0 !important;
}

.rdt_TableHeadRow {
  height: 4rem !important;
}

.rdt_TableCol > div > div {
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 1rem;
}

.nav-item a {
  display: flex !important;
  align-items: center !important;
}

.upload-photo a {
  width: 100px;
  height: 100px;
} 

.uni-title {
  display: flex !important;
  margin-right: auto !important;
  align-self: center !important;
  margin-bottom: 0;
}

// Swiper
.swiper {
  width: 100%;
  height: 30rem !important;
  overflow: visible !important;
}

.swiper-slide {
  text-align: center;
  width: 35rem !important;
  height: 16rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}

// Swiper pagination bullet
.swiper-pagination-bullet {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  width: 15px !important;
  height: 15px !important;
}

.swiper-pagination-bullets {
  position: absolute !important;
  bottom: -30px !important;
  z-index: -10 !important;
}

.swiper-pagination-bullet-active {
  background: #007CBA !important;
}

// Testimonials
.testimonials {
  height: 37.7rem !important;
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
  overflow-x: hidden !important;

  &__title {
    font-size: 1.1rem !important;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
    padding: 3rem 0;
  }

  &__text {
    font-size: 0.95rem !important;
    text-align: center;
    color: #007CBA;
    font-weight: 600 !important;
      
    &--muted {
      padding-top: 1rem;
      color: #6F6C77;
    }
  }
}

// Feedback card
.feedback-card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  width: 34rem !important;
  height: 22rem !important;
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1.5rem;

  &__header {
    display: flex;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  &__avatar {
    display: flex;
    width: 4rem;
    height: 4rem;
    position: relative;
  }

  &__name {
    font-size: 1rem;
    text-align: left;
  }

  &__student-info {
    font-size: 0.85rem;
    text-transform: uppercase;
    text-align: left;
    color: #007CBA;
  }

  &__feedback-text {
    padding: 0rem 2rem 0rem 1rem !important;
    font-size: 0.85rem;
    text-align: justify;
  }

  &--light {
    background: #FFFFFF;
    color: #6F6C77;
  }

  &--dark {
    background: #161D31;
    color: #D0D2D6;
  }

  .avatar-img {
    border-radius: 50%;
    border: 0.2rem solid #007CBA;
  }

  .country-flag {
    position: absolute;
    width: 1.5rem !important;
    height: 0.9rem !important;
    right: 0;
    bottom: 0;
    border-radius: 1px;
  }
}

// Students metrics 
.students-metrics {
  &__title {
    font-size: 1.1rem !important;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
    padding: 2rem 1rem 0;
  }

  &__subtitle {
    font-size: 0.8rem !important;
    text-align: right;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600 !important;
  }
}

// Groups
.groups-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  font-size: 0.8rem;
  font-weight: 600;
  text-align: left;
  padding: 0.5rem 1.3rem !important;
}

.avatar-content {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

// Feedback navigation
.feedback-navigation-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 8rem;
  padding-bottom: 2rem;
  z-index: 10 !important;
}

.feedback-navigation-wrapper div {
  cursor: pointer;
}

// Total Connections
.connections-card__footer {
  background: #007CBA26 !important;
  color: #007CBA;
  padding: 0.5rem 0;
  font-size: 0.85rem;
  word-break: keep-all;
}

// Quotes
.quote {
  width: 3.5rem !important;
  height: 3rem !important;
  position: absolute;

  &__left {
    left: 3.8%;
    top: 7%;
  }

  &__right {
    right: 3.8%;
    bottom: 7%;
  }
}

// Scrollbar
.simplebar-scrollbar::before {
  background-color: #007CBA !important;
  opacity: 0.4 !important; 
}

// Students config
.students-config__subtitle {
  font-size: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.datetime-picker-calendar {

  & button {
    border-radius: 4px;
    font-size: 12px !important;
  }
}

.react-calendar__tile--active {
  background-color: #007CBA !important;
}
.react-datetime-picker {
  &__wrapper {
    border: none !important;

    input {
      color: #B4B7BD !important;

      &:focus-visible {
        outline-color: #007CBA !important;
        outline-width: 2px !important;
        border-radius: 2px !important;
      }
    }
  }

  &__inputGroup {
    &__leadingZero {
      color: #B4B7BD !important;
    }

    &__input:invalid {
      background: rgba(0, 124, 186, .1) !important;
    }

    &__amPm {
      color: #B4B7BD !important;
      &:focus-visible {
        outline-color: #007CBA !important;
        outline-width: 2px !important;
        border-radius: 2px !important;
      }
    }
  }
}
.group-image {
  border:4px solid transparent;
  transition: .1s;
  &--selected {
    border:4px solid #00D5FF;
    box-shadow: 4.9px 9.8px 9.8px hsl(0deg 0% 0% / 0.35);
  }
}

// Module list custom scrollbar
.module-list__row {
  gap: 42px !important;
  overflow-x: scroll !important;
  scrollbar-color: #007CBA rgba(0, 124, 186, 0.1);
  scrollbar-width: thin;
}

.module-list__row::-webkit-scrollbar {
  height: 8px !important;
}
 
.module-list__row::-webkit-scrollbar-thumb {
  background: #007CBA;
}

.module-list__row::-webkit-scrollbar-thumb:active {
  background: cyan;
}

.module-list__row::-webkit-scrollbar-track {
  background: rgba(0, 124, 186, 0.1) !important;
}

.platform-filter {
  min-width: 125px;
  margin-right: 14px;
}

.table-spinner {
  width: 4rem;
  height: 4rem;
}

.custom-tooltip {
  & span {
    &::before {
      border-top-color: #0C93D6 !important;
    }
  }

  &__inner {
    background-color: #0C93D6 !important;
  }
}

// Basic settings update university button
.update-uni {
  &__button:hover {
    box-shadow: none !important;
  }
}

// Edit university page - navigation 
.navigation-dark {
  font-family: $font-family-monospace;
  color: #b4b7bd !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  font-weight: 450 !important;
}

.navigation-light {
  font-family: $font-family-monospace;
  color: #5e5873 !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  font-weight: 450 !important;
}

.Mui-selected {
  color: #007cba !important;
}

.MuiTabs-scrollButtons {
  color: #007cba !important;
  font-weight: 800 !important;
}

.goin-score-feedback {
  &__question {
    font-size: 11px;
  }

  &__answer {
    font-size: 10px;
    line-height: 17px;
  }

  &__hyphen {
    display: inline-block;
    margin-right: 4px;
  }
}

//Univeristy select
.custom-select {
  width: 250px !important;
}

// Vertical side bar
.menu-item {
white-space: pre-wrap;
}

.edit-role__button {
  border: none !important;
  padding: 0rem 0.5rem !important;
  color: #007cba;
}

// Text editor block height
.public-DraftEditor-content {
  min-height: 30rem !important;
}

//--------------- Analysis Card ---------------
//------------------------------------------------

.card-analysis {
  .analysis-body {
    padding: 1rem 0rem 1rem !important;
  }

  .analysis-button {
    &:hover {
      color: #99ecf6;
    }

    &:focus {
      outline: none;
      border: none !important;
      text-decoration: underline;
    }
  }
}

// Sentiment Scale

.sentiment-scale-container {
  width: 100%;
  position: relative;

  .sentiment-scale {
      position: relative;
      height: 14px;
      width: 100%;
      background: linear-gradient(to right, red, yellow, green);
      border-radius: 10px;

      .scale-dot {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 14px;
          height: 14px;
          background: linear-gradient(135deg, #f0f0f0, #c0c0c0);
          border-radius: 50%;
          border: 2px solid #666;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          cursor: pointer;

          .tooltip {
              position: absolute;
              top: -30px;
              left: 50%;
              transform: translateX(-50%);
              padding: 5px 10px;
              background-color: #333;
              color: #fff;
              border-radius: 4px;
              font-size: 12px;
              white-space: nowrap;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
              z-index: 10;
              visibility: hidden;
              opacity: 0;
              transition: visibility 0s, opacity 0.2s ease-in-out;
          }

          &:hover .tooltip {
              visibility: visible;
              opacity: 1;
          }
      }
  }

  .scale-part {
      position: absolute;
      top: 0;
      height: 20px;
      cursor: pointer;

      .tooltip {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
          padding: 5px 10px;
          background-color: #333;
          color: #fff;
          border-radius: 4px;
          font-size: 12px;
          white-space: nowrap;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s, opacity 0.2s ease-in-out;
      }

      &:hover .tooltip {
          visibility: visible;
          opacity: 1;
      }
  }

  .scale-values {
      display: flex;
      margin-top: 8px;
      font-size: 14px;
      justify-content: space-between;
      
  }
}

// Integration impact

.integration-impact {
  &__title {
    padding-top: 1rem;
    padding-left: 1.5rem;
    margin-bottom: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  &__subtitle {
    padding-left: 1.5rem;
    padding-top: 1rem;
  }
}

.integration-impact__study-img {
  width: 40%;
}

.integration-impact__nationality-img {
  width: 45%;
}

.integration-progress {
  margin: auto;
  width: 70%;
}

.bot-form {
  &__camera-icon {
    bottom: -2px !important;
    right: 0;
    color: #ffffff;
    background-color: #959EA6;
    padding: 7.9px;
    width: 38px;
    height: 40px;
  }

  &__divider {
    margin-top: 75px;
  }

  &__submit-btn {
    max-width: 150px;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .bot-form {
    &__divider {
      margin-top: 0;
    }

    &__submit-btn {
      margin: 0 auto;
    }
  }
}